<template>
    <section id="content">
        <base-card>
            <post :value="post" />
        </base-card>
    </section>
</template>

<script>
    export default {
        components: {
            Post: () => import('@/components/Post')
        },
        props: {
            post: {
                type: Object,
                default: () => ({})
            }
        },
    }
</script>
